<template>
  <div class="my-wrapper">
    <div class="my-list-wrapper">
      <ListItem
        title="新手机号"
      >
        <input type="text" slot="value" placeholder="请输入新手机号码">
      </ListItem>
      <ListItem
        title="验证码"
        :borderBottom="borderBottomStatus"
      >
        <div class="qrcode" slot="value">
          <input type="text" placeholder="点击获取验证码">
          <span>获取</span>
        </div>
      </ListItem>
      <Button
        @handleButtonClick="handleSubmitClick"
        class="submit-btn"
      >更改</Button>
    </div>
  </div>
</template>

<script>
  import ListItem from '@/components/ListItem'
  import Button from '@/components/Button'
  export default {
    name: 'ModifyPhone',
    components: {
      ListItem,
      Button
    },
    data () {
      return {
        borderBottomStatus: false,
      }
    },
    methods: {
      handleSubmitClick () {

      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../style/mixin";
  .my-wrapper{
    @include wh(100%, 100%);
    background-color: #F1F1F1;
    box-sizing: border-box;
    padding-top: 1rem;
    .my-list-wrapper{
      @include wh(100%, 6rem);
      background-color: white;
      input{
        width: 100%;
        height: 100%;
      }
      .qrcode{
        position: relative;
        input{
          box-sizing: border-box;
          padding-right: 3rem;
        }
        span{
          position: absolute;
          right: 1rem;
          top: 0.2rem;
          color: #4A8CFF;
          font-size: 0.9rem;
        }
      }
      .submit-btn{
        width: 90%;
        margin: 1rem auto;
      }
    }
  }
</style>
